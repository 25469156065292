<template>
  <div class="home">
    <Carousel></Carousel>
    <BotoneraCategorias class="mt-3"></BotoneraCategorias>
    <h2 class="mt-3 separador-rosa text-center text-white">Más Vendidos</h2>
    <b-container>
      <b-row>
        <b-col align-self="baseline">
          <GaleriaSimple :productos="bestSellers" class="d-none d-md-block"></GaleriaSimple>
          <GaleriaCarousel :productos="bestSellers" class="d-lg-none d-md-none d-xl-none"></GaleriaCarousel>
        </b-col>

      </b-row>
      
    </b-container>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/Carousels/Carousel'
import BotoneraCategorias from '@/components/BotoneraCategorias'
import GaleriaCarousel from '@/components/Carousels/GaleriaCarouselResponsive'
import GaleriaSimple from '@/components/Galerias/GaleriaSimpleFlip'
const axios = require('@/assets/functions/axiosClient.js');

export default {
  name: 'Home',
  components: {
    Carousel,
    BotoneraCategorias,
    GaleriaCarousel,
    GaleriaSimple
  },
  data(){
    return{
      bestSellers:[],
      cargando: 0
    }
  },
  methods:{
    getBestSellers(){
      this.cargando++;
      axios.get('/inventarios/productos/best-sellers?top=12&minPrice=3990')
      .then(response => 
      {
        if(response.status===200){
          console.log(response)
          this.bestSellers = response.data.data}
      }).catch(err => {
        console.log(err)
      }).finally(()=>{
        this.cargando--;
      })
    }
  },
  created(){
    this.getBestSellers();
  }
}
</script>

<style>
</style>