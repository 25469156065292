<template>
    <div>
        <b-container>
            <b-row>
                <b-col col md="6" lg="4" v-for="categoria in categorias" :key="categoria.id">
                    <b-button @click="goToTiendaCategoria(categoria)" block size="lg" pill class="my-1 btn-sexduceme">
                        {{categoria.nombre}}
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import router from '@/router'

export default {
    
    name: "BotoneraCategorias",
    props: ["categoria"],
    created(){
        this.categorias = this.obtenerCategoriasPrincipales()
    },
    data(){
        return {
            categorias: []
        }
    },
    methods:{
        goToTiendaCategoria(categoria){
                    router.push('/tienda/'+categoria.nombre)

        },
        obtenerCategoriasPrincipales(){
            return [
        {
            "id": 6,
            "nombre": "Lubricantes",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/accesorios.jpg"
        },
        {
            "id": 5,
            "nombre": "Cosmética",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/cosmetica.jpg"
        },
        {
            "id": 1,
            "nombre": "Juguetes",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/juguetes.jpg"
        },
        {
            "id": 2,
            "nombre": "Lencería",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/lenceria.jpg"
        },
        {
            "id": 24,
            "nombre": "Juegos",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/ambos.jpg"
        },
        {
            "id": 34,
            "nombre": "Ofertas Relámpago",
            "descripcion": null,
            "imagen": "https://sexduceme.cl/images/categorias/accesorios.jpg"
        }
    ]
        },
        obtenerCategoriasByPadre(categoriaId){
            console.log(categoriaId)
        },
    }
}
</script>

<style>

</style>