<template>
  <div>
    <div v-if="cargando > 0">
      <b-spinner class="text-burdeo" type="grow" label="Loading..."></b-spinner>
    </div>
    <b-container>
      <b-row>
        <b-col
          xl="2"
          lg="3"
          sm="6"
          md="4"
          v-for="producto in products"
          :key="producto.id"
        >
          <Card class="mt-3" :producto="producto"></Card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Card from "@/components/Cards/FlipCard";
export default {
  name: "GaleriaSimpleFlip",
  props: ["categoriaId", "caracteristica", "productos"],
  components: {
    Card,
  },
  data() {
    return {
      products: [],
      cargando: 0,
    };
  },
  watch: {
    productos: function (newValue) {
      this.products = newValue;
      this.cargando--;
    },
  },
  methods: {
    getProductosByCategoria(categoriaId) {
      console.log(categoriaId);
    },
  },
  created() {
    if (this.productos) {
      this.cargando++;
      this.products = this.productos;
    } else if (this.categoriaId) {
      this.products = this.getProductosByCategoria(this.categoriaId);
    } else if (this.caracteristica) {
      this.products = this.searchProductos(this.caracteristica);
    }
  },
};
</script>

<style>
</style>