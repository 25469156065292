<template>
  <div>
    <b-container fluid="sm">
      <b-row>
        <b-col cols="9">
          <b-form-input
            size="sm"
            placeholder="Buscar Producto"
            v-model="busqueda"
            @keyup.enter="clickBuscar()"
          ></b-form-input>
        </b-col>
        <b-col cols="2">
          <b-button @click="clickBuscar()" size="sm" class="btn-sexduceme"
            ><b-icon icon="search"></b-icon
          ></b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

// const axios = require('@/assets/functions/axiosClient')
import router from '@/router'
export default {
  name: "Buscador",
  data(){
      return {
          busqueda:null
      }
  },
  methods:{
      clickBuscar(){
          if(!this.busqueda){
              this.makeToast('danger', "Error al Buscar","El cuadro de búsqueda se encuentra vacío, por favor indique alguna característica del producto que anda buscando")
          }
          else{
            this.buscarProductos(this.busqueda);
          }
      },
      buscarProductos(busqueda){
        router.push('/tienda?busqueda='+busqueda.replaceAll(' ','-'))
            router.go()
          // console.log(busqueda)
          // axios.get('/inventarios/productos/busqueda/'+busqueda.replaceAll(' ','-')).then(response => {
          //   console.log(response.data)
          //   router.push('/tienda?busqueda='+busqueda.replaceAll(' ','-'))
          //   router.go()
          // })
          // .catch(err=>{
          //   console.log(err)
          // })
          
      },
      makeToast(variant = null, titulo, text) {
        this.$bvToast.toast(text, {
          title: titulo,
          variant: variant,
          solid: true
        })
      }
  }
};
</script>