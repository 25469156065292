<template>
  <div>
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      controls
      indicators
      img-width="1024"
      img-height="480"
    >
      <b-carousel-slide
        v-for="imagen in imagenes"
        :key="imagen.id"
        caption=""
        :img-src="imagen.src"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  data() {
    return {
      imagenes: [],
    };
  },
  methods: {
    ..._,
    getImagesCarousel() {
      let images = require.context("@/assets/imgs/sliceImages/", true, /^.*\.png$/);
      console.log(images);console.log(images.keys())
      images.keys().forEach((imagen,i=0) => {
        this.imagenes.push({
          id: i,
          src: require("@/assets/imgs/sliceImages/"+imagen.split("/")[1]),
          url: null,
          orden: i++ 
        })
      });
      console.log(this.imagenes)
      // this.imagenes = [
      //   {
      //     id: 1,
      //     src: require("@/assets/imgs/pendon.jpg"),
      //     url: null,
      //     orden: 1,
      //   },
      //   {
      //     id: 2,
      //     src: require("@/assets/imgs/vacaciones_sd.png"),
      //     url: null,
      //     orden: 2,
      //   },
      //   {
      //     id: 3,
      //     src: require("@/assets/imgs/welcome_2022_sd.png"),
      //     url: null,
      //     orden: 3,
      //   },
      // ];
    },
  },
  created() {
    this.getImagesCarousel();
  },
};
</script>