<template>
  <div>
    <div v-if="cargando > 0">
      <b-spinner class="text-burdeo" type="grow" label="Loading..."></b-spinner>
    </div>
    <carousel>
      <slide v-for="producto in products" :key="producto.id">
        <Card :producto="producto"></Card>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
// import Card from "@/components/Cards/ProductoSimple"
import Card from "@/components/Cards/FlipCard";

export default {
  name: "App",
  props: ["categoriaId", "caracteristica", "productos"],
  components: {
    Carousel,
    Slide,
    Card,
  },
  data() {
    return {
      products: [],
      cargando: 0,
    };
  },
  watch: {
    productos: function (newValue) {
      this.products = newValue;
      this.cargando--;
    },
  },
  methods: {
    getProductosByCategoria(categoriaId) {
      console.log(categoriaId);
      return [
        {
          id: 30,
          codigo: "44",
          nombre: "Feromonas Femeninas Roll On ENAMOR",
          descripcion:
            "ENAMOR es un perfume compuesto en un 100% con feromonas femeninas, que te permitirá evocar una respuesta sexual de quien gustes. Es un hecho que mujeres con niveles altos de emisión de feromonas tienen una ventaja para atraer la atención masculina o femenina según su preferencia sexual. Con ENAMOR sacarás todo tu potencial sexual. Las feromonas de atracción, como las que está compuesto ENAMOR, son las que nos indican de quién debemos enamorarnos. ",
          detalles:
            "Feromonas Femeninas\r\nAtracción Sexual\r\nExcitantes y afrodisiacos\r\nContenido: 10 ml.\r\nConcentración: 100%.\r\n\r\nAplicación: Rollon\r\n\r\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 6990,
          descuento: 28.6,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 563,
              nombre: "",
              url: "https://i.ibb.co/0fKQXSn/Pics-Art-02-27-02-14-15.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 36,
              nombre: "Monte Patria",
              descripcion:
                "Productos disponibles para entrega inmediata en la ciudad de Monte Patria, Cuarta Región",
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 226,
          codigo: "6959532318364",
          nombre: "Anillo Vibrador Doble Ajustable Locker",
          descripcion:
            "Anillo Vibrador Doble Ajustable Locker, con bala vibradora integrada para estimulación de pene y testículos. Esta especialmente diseñado para brindar comodidad y funcionalidad mejorando efectivamente la relación sexual. Hecho de silicona que se estira fácilmente para máxima comodidad, además viene con una bala vibradora lo que estimulara tanto a él como a ella.",
          detalles:
            "Caracteristicas\nMaterial Silicona\nColor Negro\nBala Vibradora integrada (3 batería LR41)\nMedidas\nLargo: 13.6cm\nAncho: 5cm\n\nObservaciones \nUsar con lubricante a base de agua\nResistente al agua\nDiseño ergonómico\nUso Externo \n\nRecomendaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 26990,
          descuento: 10.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 592,
              nombre: "",
              url: "https://i.ibb.co/HXsykX6/Pics-Art-02-09-02-34-44.jpg",
              descripcion: "",
            },
            {
              id: 593,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14834-large_default/anillo-vibrador-doble-ajustable-locker.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 190,
          codigo: "52601",
          nombre: "Corset Explosión de Color Amarillo",
          descripcion:
            "Hermoso Corset explosión de color en Amarillo. Posee ajustes con cintas en la parte trasera. \n",
          detalles:
            "Color: Amarillo\nTalla L\nIncluye Colales\n\nObservaciones: Talla pequeña, equivale a un M/L Nacional ",
          instagram: "",
          precio: 21990,
          descuento: 10.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 808,
              nombre: "",
              url: "https://i.ibb.co/C0rTDnR/Pics-Art-04-19-12-53-13.jpg",
              descripcion: "",
            },
            {
              id: 809,
              nombre: "",
              url: "https://i.ibb.co/6b1kHJ3/Pics-Art-04-19-12-52-45.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 6,
          codigo: "825156107799",
          nombre: "Juego Masaje Seducción",
          descripcion:
            "Disfruta junto a tu pareja de un momento de seducción único con el Juego Masaje Seducción que incluye tarjetas que contienen consejos e implementos para realizar sensuales masajes. Con este juego pasaras un excelente momento en pareja que los tendrá con el erotismo y sensualidad a flor de piel, provocando así un momento increíble, además junto a la vela que incluye para masajes lograra de este momento algo lujurioso y cumplirán esas fantasías que siempre quisieron realizar junto a un sensual y relajante masaje.",
          detalles:
            "El kit contiene: \n1 Vela de Cera para Masajes \n1 Almohadilla masajeadora en gel con forma de Corazón Tarjetas con Consejos para masajes.\n\nLa vela de masaje se funde en forma de aceite de masaje.\nCorazón masajeador de gel, se adapta a la temperatura corporal.\nLas tarjetas contienen consejos para realizar masajes.\nDirigido a parejas.",
          instagram: "http://www.instagram.com/sexduceme_cl",
          precio: 16990,
          descuento: 15.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 12,
              nombre: "Kit Masaje Vela Individual",
              url: "https://i.ibb.co/x7sfVmz/IMG-20201012-192319.jpg",
              descripcion: null,
            },
          ],
          categorias: [
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 29,
          codigo: "33",
          nombre: "Feromonas Masculinas Roll On ENAMOR",
          descripcion:
            "Enamor es un perfume compuesto de feromonas con una concentración de 100%. No contiene olor ni mancha, así que podrás seguir haciendo uso de tu perfume habitual sin problemas. El modo de uso es muy fácil; Aplica este perfume Enamor en el cuello o las muñecas y comienza la conquista. Se recomienda su uso cuando vayas de fiesta o a una discoteca, ya que se trata de lugares muy concurridos y, normalmente, son espacios reducidos en los que te cruzas varias veces con las mismas personas. En estos lugares hombres y mujeres están más dispuestas al romance y a encuentros sexuales.",
          detalles:
            "Características:\nFeromonas Masculinas\nContenido: 10 ml.\nConcentración: 100%\n\nAplicación: Rollon\n\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 6990,
          descuento: 28.6,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 562,
              nombre: "",
              url: "https://i.ibb.co/1Ljdv5S/Pics-Art-02-27-02-13-41.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 222,
          codigo: "6914421000232",
          nombre: "Plug Anal Metalizado Con Cola Blanca",
          descripcion:
            "Plug anal cola blanca, el plug de metal posee una cola larga en tono blanco, de diseño novedoso y elegante, perfecto para quienes se quieren iniciar en una experiencia anal gracias al tamaño del dilatador y su forma cónica. El plug anal de metal ofrece una estimulación placentera y segura. De muy fácil limpieza.",
          detalles:
            "Características:\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor Cola: Blanco\n\nMedidas:\nLargo plug: 7 cm\nDiámetro mayor: 3 cm\nDiámetro menor: 1 cm\nLargo cola: 73 cm\nMaterial cola: Polyester\n\nObservaciones: Lavar antes y después de usar. Utilizar con lubricante, perfecto para principiantes. De fácil limpieza.",
          instagram: "",
          precio: 32990,
          descuento: 10.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 530,
              nombre: "",
              url: "https://i.ibb.co/7J0nh3L/Pics-Art-02-08-03-17-35.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 118,
          codigo: "5037",
          nombre: "Colales Silver Glitter",
          descripcion: "Colales con detalles brillantes en la tela. ",
          detalles:
            "Talla única\nColor Café Claro. \n\nObservación: Talla única, recomendamos hasta M/L",
          instagram: "",
          precio: 2990,
          descuento: 25.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 214,
              nombre: "",
              url: "https://i.ibb.co/8m6SYnx/IMG-20201002-205720.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 103,
          codigo: "5022782333126",
          nombre: "Sostén Comestible Dulces de Arcoíris",
          descripcion:
            "Usar lencería nunca había sido tan divertido, descubre el delicioso multi sabor que nos ofrece el sostén Candy Bra.\n\nDiseñado para acomodarse a todo tipo de cuerpo gracias a su pabilo elástico.",
          detalles:
            "Características:\n\nProducto comestible\nMulti sabor\nTalla estándar",
          instagram: "",
          precio: 14990,
          descuento: 10.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 132,
              nombre: "",
              url: "https://i.ibb.co/Lg5Z7Ks/IMG-20201108-154939.jpg",
              descripcion: "",
            },
            {
              id: 781,
              nombre: "",
              url: "https://i.ibb.co/K28Dr0t/IMG-20201108-155050.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 104,
          codigo: "5022782333119",
          nombre: "Calzón Comestible Dulces de Arcoíris",
          descripcion:
            "Usar lencería nunca había sido tan divertido, descubre el delicioso multi sabor que nos ofrece la tanga comestible Candy G-String.Diseñado para acomodarse a todo tipo de cuerpo gracias a su pabilo elástico.",
          detalles:
            "Características:\n\nProducto comestible\nMulti sabor\nTalla estándar",
          instagram: "",
          precio: 14990,
          descuento: 15.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 134,
              nombre: "",
              url: "https://i.ibb.co/N6b1Yn1/IMG-20201108-155000.jpg",
              descripcion: "",
            },
            {
              id: 782,
              nombre: "",
              url: "https://i.ibb.co/K28Dr0t/IMG-20201108-155050.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 146,
          codigo: "6914421000256",
          nombre: "Babydoll Azul Floreado",
          descripcion:
            "Hermoso y sexy babydoll de color azul, con detalles de encaje floreado en la zona del busto. Incluye Colales.",
          detalles: "Tallas Disponibles.\n- M\n- L\n- XL",
          instagram: "",
          precio: 12990,
          descuento: 35.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 191,
              nombre: "",
              url: "https://i.ibb.co/1mQSC5T/1606606137760.jpg",
              descripcion: "",
            },
            {
              id: 192,
              nombre: "",
              url: "https://i.ibb.co/JKKfVNM/1606606137742.jpg",
              descripcion: "",
            },
            {
              id: 193,
              nombre: "",
              url: "https://i.ibb.co/KXX3N5H/1606606137724.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 147,
          codigo: "6914421000065",
          nombre: "Babydoll Negro con Rojo",
          descripcion:
            "Sexy babydoll negro, con hermosos detalles de encaje rojo en el busto. Hará encender tus noches de pasión. Incluye colales ",
          detalles: "Tallas Disponibles:\n- M\n- L\n- XL\n",
          instagram: "",
          precio: 15990,
          descuento: 25.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 194,
              nombre: "",
              url: "https://i.ibb.co/2yCLrB0/1607005989727.jpg",
              descripcion: "",
            },
            {
              id: 195,
              nombre: "",
              url: "https://i.ibb.co/BngSz0t/1607005989710.jpg",
              descripcion: "",
            },
            {
              id: 196,
              nombre: "",
              url: "https://i.ibb.co/hR5qrqK/1607005989693.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 191,
          codigo: "700604737158",
          nombre: "Corset Explosión de Color Verde",
          descripcion:
            "Hermoso Corset explosión de colores verde. Posee ajuste con cintas en la parte trasera.\n",
          detalles:
            "Talla XL \nColor Verde con detalles de otros colores\nMaterial Polyester y Spandex\nIncluye Colales \n\nObservación: Talla pequeña, equivale a un L/XL Nacional.",
          instagram: "",
          precio: 21990,
          descuento: 10.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 810,
              nombre: "",
              url: "https://i.ibb.co/CmwcQHN/Pics-Art-04-19-12-51-49.jpg",
              descripcion: "",
            },
            {
              id: 811,
              nombre: "",
              url: "https://i.ibb.co/j64FpgD/Pics-Art-04-19-12-52-17.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 289,
          codigo: "825156107621",
          nombre: "Juego de Trivia - Noche de Chicas",
          descripcion:
            "Aprende con y de tus amigas a través de 4 categorías de interesantes e íntimas preguntas.\n\nAlgunas preguntas de ejemplo:\n\n¿Cuál de mis antiguos pololos me gustaría más que me contacte?\n\n¿ Cuál es mi vicio favorito ?\n\nConviértete en la ganadora siendo la mejor en acertar las respuestas de tus amigas.\nDiversión garantizada para todas las asistentes",
          detalles:
            'Incluye: Una ruleta de Noche de Chicas, un block de hojas para el juego, 40 cartas con preguntas " Cosas de la vida", 40 cartas con preguntas sobre " Personalidad", 40 cartas con preguntas sobre "Amores y amantes" y 40 cartas con preguntas sobre " Salud y Belleza"',
          instagram: "",
          precio: 15990,
          descuento: 10.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 734,
              nombre: "",
              url: "https://i.ibb.co/10kH31s/Pics-Art-03-21-12-36-23.jpg",
              descripcion: "",
            },
            {
              id: 735,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/5298177/resize/635/635?1595545184",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 203,
          codigo: "3005",
          nombre: "Pantaleta de Encaje",
          descripcion: "Pantaletas con hermoso diseño de encaje.",
          detalles:
            "Colores y Tallas Disponibles: \n- Negro (S - M)\n- Nude (S - M)\n- Rosado (XL)\n- Calipso (M)\n- Blanco (L - XL)\n\nOBSERVACIÓN:\nSon tallas pequeñas, por lo que se recomienda que solicite una talla más a la que utiliza regularmente.",
          instagram: "",
          precio: 4990,
          descuento: 20.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 358,
              nombre: "",
              url: "https://i.ibb.co/pw7ymGG/Pics-Art-01-18-11-21-27.jpg",
              descripcion: "",
            },
            {
              id: 359,
              nombre: "",
              url: "https://i.ibb.co/rwHZXtr/Pics-Art-01-18-11-23-22.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 204,
          codigo: "J303",
          nombre: "Portaligas",
          descripcion:
            "Portaligas con detalles de encaje en la parte inferior. \nPerfectas para combinar con tus pantys ligas favoritas. ",
          detalles:
            "Talla Estándar\nMaterial: Razo y encaje\nColores:\n- Rojo\n- Negro\n- Blanco",
          instagram: "",
          precio: 7990,
          descuento: 15.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 360,
              nombre: "",
              url: "https://i.ibb.co/G7HtTCJ/Pics-Art-01-18-11-22-25.jpg",
              descripcion: "",
            },
            {
              id: 361,
              nombre: "",
              url: "https://i.ibb.co/VjZ9Fdr/Pics-Art-01-18-11-26-31.jpg",
              descripcion: "",
            },
            {
              id: 362,
              nombre: "",
              url: "https://i.ibb.co/WxwgG2D/Pics-Art-01-18-11-27-27.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 219,
          codigo: "442002071112",
          nombre: "Rotador Delfin Aqua Lover",
          descripcion:
            "Sumérgete en el más profundo e intenso placer con el Rotador Delfín Aqua Lover resistente al agua. Su estructura móvil giratoria, en dirección a ambos sentidos, proporciona una mayor sensación de penetración. Sus perlas están diseñadas para masajear y estimular las paredes vaginales, permitiéndote a su vez, estimular tu punto G, por la curvatura que tiene en la parte superior.\nAdicionalmente, está acompañado de un pequeño estimulador de clítoris con 12 vibraciones y pulsaciones diferentes totalmente controlables, que te llevarán a graduar y prolongar tus orgasmos.",
          detalles:
            "Características:\nLargo total: 21,5 cm.\nLargo penetrable: 10,5 cm.\nDiámetro: 3 cm.\nCircunferencia: 10,5 cm.\nMaterial: Jelly\nPeso: 180 grs.\nBaterías: 3 pilas AAA (No incluidas).\nSku: 44200207111\n\nEstimulador de clítoris Delfín:\nEstimulador delfín con 12 pulsaciones y vibraciones distintas.\nLargo total: 5,5 cm.\n\nRecomendaciones:\nLimpiar antes y después de utilizar, con agua tibia y jabón neutro. Guardar en un sitio limpio y seco, sin mezclar con otros juguetes. Usar con lubricante a base de agua y con preservativo.",
          instagram: "",
          precio: 32990,
          descuento: 20.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 518,
              nombre: "",
              url: "https://i.ibb.co/sKXQHVv/Pics-Art-02-08-03-51-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 15,
              nombre: "Rotadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 335,
          codigo: "6914421000072",
          nombre: "Pack Lencería Comestible",
          descripcion:
            "Usar lencería nunca había sido tan divertido, descubre el delicioso multi sabor que nos ofrece el combo candy.",
          detalles:
            "Características:\n\nProducto comestible\nMulti sabor\nTalla estándar",
          instagram: "",
          precio: 29980,
          descuento: 30.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 917,
              nombre: "",
              url: "https://i.ibb.co/K28Dr0t/IMG-20201108-155050.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 232,
          codigo: "6959532313475",
          nombre: "Bala Vibradora Dana",
          descripcion:
            "Bala Vibradora Curva Dana es un estimulador unisex el cual utiliza una pila AAA, de material siliconado y tiene 10 patrones de vibración.\nTenemos para ti un excelente producto ergonómico y discreto ideal para llevar en nuestros bolsillos, se trata de la Bala Vibradora Dana magnifico producto que ha sido diseñado especialmente para estimular clítoris, punto G y punto P. Su material de silicona muy suave que cuida tu piel  está libre de ftalatos, de fácil modo de uso y cuenta con un único botón del cual se apaga, prende y se activan los patrones de vibración.",
          detalles:
            "Características:\n10 Patrones de vibración\nMaterial: silicona Libre de ftalatos\nBatería: 1 pila AAA\nProveedor: Pretty Love\nColor purpura\n\nMedidas:\nDiámetro: 2,2 cm\nLargo: 10,6 cm\nPeso 40 gramos\n\nObservaciones:\nBala vibradora unisex\nResistente al agua (no sumergible)\nApto para lubricantes a base de agua o híbridos\n\nModo de uso:\nPresionar el botón que está ubicado en la parte inferior del juguete de ahí podrás encenderlo, apagarlo y cambiar intensidades de vibración.\n\nRecomendaciones\nLimpiar antes y después del uso, para encender mantener presionado y luego elegir la función.",
          instagram: "",
          precio: 19990,
          descuento: 15.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 578,
              nombre: "",
              url: "https://i.ibb.co/9v3XknM/Pics-Art-02-09-02-38-42.jpg",
              descripcion: "",
            },
            {
              id: 579,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14729-large_default/bala-vibradora-curva-dana.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 36,
              nombre: "Monte Patria",
              descripcion:
                "Productos disponibles para entrega inmediata en la ciudad de Monte Patria, Cuarta Región",
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 189,
          codigo: "700381918382",
          nombre: "Corset Café con Correas",
          descripcion:
            "Hermoso corset, con correas y tachas, posee cierre frontal y ajuste de cintas en la parte de la espalda. Incluye colales\n\n",
          detalles:
            "Características:\nMaterial: Polyester Spandex\nColor: Café\nNúmero Prendas: 2 (corset + colaless)\nTalla: M\n\nObservación: \nTalla M pequeña, equivale a una talla XS/S Nacional.",
          instagram: "",
          precio: 21990,
          descuento: 10.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 806,
              nombre: "",
              url: "https://i.ibb.co/ZVvgK9C/Pics-Art-04-19-12-50-45.jpg",
              descripcion: "",
            },
            {
              id: 807,
              nombre: "",
              url: "https://i.ibb.co/Tqy443b/Pics-Art-04-19-12-51-19.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 145,
          codigo: "6914421000263",
          nombre: "Babydoll Morado",
          descripcion:
            "Hermoso y delicado babydoll morado, posee detalles de encaje a tono en la zona de la cintura. Incluye colales a juego.",
          detalles:
            "Talla Estándar.\n\nSe recomienda para talla XS - S, puesto que son tallas pequeñas. ",
          instagram: "",
          precio: 12990,
          descuento: 10.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 188,
              nombre: "",
              url: "https://i.ibb.co/J7ZXztb/1606606137707.jpg",
              descripcion: "",
            },
            {
              id: 189,
              nombre: "",
              url: "https://i.ibb.co/7kKJq9T/1606606137689.jpg",
              descripcion: "",
            },
            {
              id: 190,
              nombre: "",
              url: "https://i.ibb.co/h9XkdwZ/1606606137671.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 129,
          codigo: "8035",
          nombre: "Disfraz Masculino de Marinero",
          descripcion:
            "Haz que se le mueva el piso y se sienta en altamar con este Sexy Disfraz de Marinero Fiestero.\nEl Disfraz Marinero Fiestero consta de un Boxer elasticado el cual tiene detalles en líneas negras y rojas en fondo blanco, entregando así el diseño clásico de marinero. Este disfraz tiene también una pañoleta y gorra, ambos a juego.",
          detalles:
            "Características\nMaterial: Poliéster - Elastano\nColor: Negro, Blanco, Rojo\nPrendas: Boxer - Pañoleta - Gorra  \n\nTalla: Única (O/S)\n\nObservaciones\n- Lavar a mano\n- Elasticado\n- Recomendamos para talla S/M",
          instagram: "",
          precio: 18990,
          descuento: 15.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 236,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0348/6144/9348/products/14408035001_2000x.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 3,
              nombre: "Lencería Masculina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
      ];
    },
  },
  created() {
    if (this.productos) {
      this.cargando++;
      this.products = this.productos;
    } else if (this.categoriaId) {
      this.products = this.getProductosByCategoria(this.categoriaId);
    } else if (this.caracteristica) {
      this.products = this.searchProductos(this.caracteristica);
    }
  },
};
</script>