<template>
  <div v-bind:class="flipped ? 'flip-container flipped' : 'flip-container'">
    <div class="flipper">
      <div class="front">
        <slot name="front"></slot>
        <div class="frontFlipBtn" v-on:click="flipped = true">
          <!-- PARTE DELANTERA DE LA CARD -->
          <div>
            <b-card
              :title=producto.nombre
              :img-src=producto.imagenes[0].url
              img-alt="Image"
              img-top
              tag="article"
              title-tag="span"
              style="max-width: 20rem"
              class="mb-2 h-350px"
            >
            <!-- <b-card-text>{{producto.descripcion.substring(0,100)+"..."}}</b-card-text> -->
              <b-card-text>
                {{formatPrice(producto.precio)}}
              </b-card-text>

            </b-card>
          </div>
          <!-- FIN DE LA CARD DELANTERA -->
        </div>
      </div>
      <div class="back">
        <slot name="back"></slot>
        <div class="backFlipBtn" v-on:click="flipped = false">
          <!-- CARD TRASERA -->
          <div>
            <b-card
              title=""
              title-tag="h5"
              tag="article"
              style="max-width: 20rem"
              class="mb-2 h-350px"
            >
              <b-card-text class="text-break truncado">
                {{producto.descripcion.substring(0,200)+"..."}}
              </b-card-text>

            </b-card>
          </div>
          <!-- FIN DE LA CARD TRASERA -->
        </div>
      </div>
    </div>
    <b-button block pill @click="goToProductoDetail(producto)" size="sm" class="btn-sexduceme">Más Detalles</b-button>
  </div>
</template>

<script>
import router from '@/router'
import {formatPrice} from '@/assets/functions/formaters'
export default {
  name: "FlipCard",
  props:["producto"],
  data: function () {
    return {
      flipped: false,
    };
  },
  methods:{
    formatPrice,
    goToProductoDetail(producto){
      router.push("/producto/"+producto.codigo+"/"+producto.nombre)
    }
  }
};
</script>

<style type='text/css' scoped>
i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
}
i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}

.h-350px{
    height: 350px;
}
</style>