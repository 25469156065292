<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
     -->
    <Header :key="headerKey"></Header>
    <!-- CARGANDO -->
    <b-container>
      <b-row>
        <b-col>
    <router-view @accion="processAccion($event)" />

        </b-col>
      </b-row>
    </b-container>
    <Footer @hook:mounted="countComponents()"></Footer>
  </div>
</template>
<script>

import Header from '@/layout/Header'
import Footer from '@/layout/Footer'
export default {
  components:{
    Header,
    Footer
  },
  watch:{
    componentes: function(newValue){
      console.log("Componentes Cargados: "+newValue)
    }
  },
  data(){
    return{
      componentes: 0,
      headerKey: 0
    }
  },
  methods:{
    processAccion(event){
      console.log("event in App: "+event)
      if(event==='refreshCart'){
        this.actualizarHeader();
      }
    },
    actualizarHeader(){
      this.headerKey++;
    },
    countComponents(){
      this.componentes++;
    },
    discountComponents(){
      this.componentes--;
    },
    cargando(){
      return true;
    }
  },
  created(){
    // const h = this.$createElement
    //     // More complex structure
    //     const messageVNode = 
    //       h('b-img', {
    //         props: {
    //           src: require("@/assets/modal_vacaciones.png"),
    //           thumbnail: true,
    //           center: true,
    //           fluid: true,
    //         }
    //       })
        
    //     // We must pass the generated VNodes as arrays
    //     this.$bvModal.msgBoxOk([messageVNode], {
    //       // title: "YA VOLVEMOS!",
    //       centered: true, 
    //       size: 'md'
    //     })
  }
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<style>
.separador-rosa{
  background-color: #f8afcc;
  color: #730b32;
  padding: 1em;
  font-weight: bold;
}

</style>