<template>
  <b-button @click="$router.push('/carro')" pill variant="outline-success">Carrito / {{sumarCarro()}} <b-icon icon="basket"></b-icon></b-button>
</template>

<script>
import {formatPrice} from '@/assets/functions/formaters'
export default {
    methods:{
    sumarCarro(){
            let suma = 0;
            this.carro.forEach(producto => {
                suma += producto.cantidad*producto.precio;
            });
            return formatPrice(suma);
        }
  },
  created(){
        this.carro = JSON.parse(localStorage.getItem('carrito'));
        if(this.carro===null)
            this.carro=[];
    },
    updated(){
        this.carro = JSON.parse(localStorage.getItem('carrito'));
        if(this.carro===null)
            this.carro=[];
    }

}
</script>

<style>

</style>