<template>
  <div class="separador-rosa mt-5">
    <b-container>
      <b-row>
        
        <b-col xl="4" sm="12">
          <span class="text-white text-left">Somos Sexdúceme</span>
          <b-navbar-nav>
          <b-nav-item class="text-left"><router-link to="/nosotros#quienes-somos"><span class="text-white">
            <b-icon class="mr-2" icon="file-earmark-person"></b-icon>
            Quienes Somos</span></router-link>
          </b-nav-item>
          <b-nav-item class="text-left"><router-link to="/nosotros#equipo"><span class="text-white">
            <b-icon class="mr-2" icon="file-earmark-person"></b-icon>
            Nuestro Equipo</span></router-link>
          </b-nav-item>
          <!-- <b-nav-item class="text-left"><router-link to="/nosotros"><span class="text-white">
            <b-icon class="mr-2" icon="bullseye"></b-icon>
            Misión</span></router-link>
          </b-nav-item>
          <b-nav-item class="text-left"><router-link to="/nosotros"><span class="text-white">
            <b-icon class="mr-2" icon="binoculars"></b-icon>
            Visión</span></router-link
          ></b-nav-item> -->
        </b-navbar-nav>
          
        </b-col>

        <b-col xl="4" sm="6">
          <span class="text-white text-left bold">Encuéntranos</span>
          <b-card-text class="text-white text-left small my-3">
            <p class="text-justify">
              <b-icon class="grande mr-2" icon="geo-alt"></b-icon>
            <b-link target="blank" class="text-white" href="https://g.page/Sexduceme?share">Parque Magallanes #1460, Bosque San Carlos, Coquimbo.</b-link>
            </p>
          </b-card-text>
          <div class="text-white text-left">
            <b-row class="align-middle text-left">
              <b-card-text class="text-left my-3 col-2">
                <b-link target="blank" class="text-white" href="https://wa.me/56997322024"><font-awesome-icon class="grande" size="lg" :icon="['fab', 'whatsapp']"/></b-link>
              </b-card-text>
              <b-col cols="8" class="h-100 text-sm-small text-md-large text-center" style="margin: 5% 0% 5% 0%">
                <span class="">+56 9 9732 2024</span>
              </b-col>
              <b-card-text class="text-left my-3 col-2 text-left">
                <b-link target="blank" class="text-white text-left" href="tel:+56997322024"><b-icon class="grande" icon="telephone-forward"></b-icon></b-link>
              </b-card-text>
            </b-row>
          </div>
          
        </b-col>

        <b-col xl="4" sm="6" md="6">
          <span class="text-white text-left bold">Políticas de Compra</span>
          <b-navbar-nav>
          <b-nav-item class="text-left"><router-link to="/politicas"><span class="text-white">
            <b-icon class="mr-2" icon="cart4"></b-icon>
            Políticas de Compra</span></router-link>
          </b-nav-item>
          <b-nav-item class="text-left"><router-link to="/politicas"><span class="text-white">
            <b-icon class="mr-2" icon="truck"></b-icon>
            Políticas de Envío</span></router-link>
          </b-nav-item>
          <b-nav-item class="text-left"><router-link to="/politicas"><span class="text-white">
            <b-icon class="mr-2" icon="credit-card-2-front"></b-icon>
            Políticas de Pago</span></router-link>
          </b-nav-item>
          <!-- <b-nav-item class="text-left"><router-link to="/nosotros"><span class="text-white">
            <b-icon class="mr-2" icon="bullseye"></b-icon>
            Misión</span></router-link>
          </b-nav-item>
          <b-nav-item class="text-left"><router-link to="/nosotros"><span class="text-white">
            <b-icon class="mr-2" icon="binoculars"></b-icon>
            Visión</span></router-link
          ></b-nav-item> -->
        </b-navbar-nav>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>

.grande{
  font-size: 2em;
}

</style>