<template>
  <div id="header" class="mb-1">
    <!-- NIVEL 1 -->
    <b-container class="d-none d-md-block">
      <b-row
        align-v="center"
        align-h="center"
        class="justify-content-md-center"
      >
        <b-col>
          <div class="mr-auto">
            <Buscador></Buscador>
          </div>
        </b-col>
        <b-col>
          <b-img
            class="seleccionable"
            @click="$router.push('/')"
            :src="require('../assets/logo_100x200.png')"
            alt="logo sexduceme"
          ></b-img>
        </b-col>
        <b-col>
          <ButtonCarro></ButtonCarro>
        </b-col>
      </b-row>
    </b-container>

    <!-- Header en móvil -->
    <b-container class="d-lg-none d-md-none d-xl-none">
      <b-row
        align-v="center"
        align-h="center"
        class="justify-content-md-center"
      >
        <b-col cols="6">
          <div class="mr-auto">
            <Buscador></Buscador>
          </div>
        </b-col>
        <b-col cols="6">
          <ButtonCarro></ButtonCarro>
        </b-col>
        <b-col>
          <b-img
            :src="require('@/assets/logo_100x200.png')"
            alt="logo sexduceme"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>

    <!-- NIVEL 2 -->
    <b-navbar toggleable="lg" type="dark" class="bg-rosa">
      <b-navbar-brand
        ><span class="text-burdeo">Sexduceme Sexshop</span></b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="analyticsAddEvent('navegacion','header','inicio')"
            ><router-link to="/"
              ><span class="text-burdeo"><b-icon icon="house-door"></b-icon> Inicio</span></router-link
            ></b-nav-item
          >
          <b-nav-item @click="analyticsAddEvent('navegacion','header','nosotros')"
            ><router-link to="/nosotros"
              ><span class="text-burdeo"><b-icon icon="people"></b-icon> Nosotros</span></router-link
            ></b-nav-item
          >
          <b-nav-item  @click="analyticsAddEvent('navegacion','header','tienda')"
            ><router-link to="/tienda"
              ><span class="text-burdeo">
                <b-icon icon="cart2"></b-icon> Tienda</span
              ></router-link
            ></b-nav-item
          >
          <b-nav-item @click="analyticsAddEvent('navegacion','header','politicas')"
            ><router-link to="/politicas"
              ><span class="text-burdeo">
                <b-icon icon="patch-question"></b-icon> ¿Cómo Comprar?</span
              ></router-link
            ></b-nav-item
          >
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-row>
            <b-col  @click="analyticsAddEvent('navegacion','header','logo-facebook')"
              ><b-nav-item href="https://www.facebook.com/sexduceme.cl" target="blank"
                ><b-icon
                  class="text-burdeo"
                  icon="facebook"
                ></b-icon></b-nav-item
            ></b-col>
            <b-col @click="analyticsAddEvent('navegacion','header','logo-instagram')"
              ><b-nav-item href="https://www.instagram.com/sexduceme_cl" target="blank"
                ><b-icon
                  class="text-burdeo"
                  icon="instagram"
                ></b-icon></b-nav-item
            ></b-col>
            <b-col @click="analyticsAddEvent('navegacion','header','logo-whatsapp')"
              ><b-nav-item href="https://wa.me/56997322024" target="blank"
                ><font-awesome-icon
                  class="text-burdeo"
                  size="lg"
                  :icon="['fab', 'whatsapp']" /></b-nav-item
            ></b-col>
          </b-row>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import Buscador from "@/components/inputs/Buscador";
import ButtonCarro from "@/components/inputs/ButtonCarro";
import {analyticsAddEvent} from "@/assets/functions/analytics"
export default {
  components: {
    Buscador,
    ButtonCarro,
  },
  methods:{
    analyticsAddEvent
  }
};
</script>

<style>
</style>